<template>
  <div class="mt-5" v-async="loading">
    <div class="row">
      <div class="d-flex align-items-center">
        <form-row-header
          text="Envio automático de Demonstrativo de Faturamento"
        />
        <help v-if="showDemonstrativoHelp" class="ms-2" boxAlign="right">
          Para enviar demonstrativos automáticos aos contatos parametrizados,
          configure aqui o envio automático para "Enviar por e-mail"
        </help>
      </div>
    </div>
    <div v-if="model" class="row mt-4">
      <div class="d-flex align-items-center">
        <div class="col col-2">
          <label class="cursop" for="empresa_configuracao_DF_nao_recebe">
            <input
              :disabled="
                !$can('CENTRAL_CADASTROS_EDITAR') &&
                !$can('CENTRAL_CADASTROS_APROVAR') &&
                !$can('CENTRAL_CADASTROS_PARAMETRIZAR')
              "
              id="empresa_configuracao_DF_nao_recebe"
              :value="EmpresaConfiguracaoDemonstrativoEnum.NAO_RECEBE"
              type="radio"
              v-model="model.tipoEnvioDemonstrativo"
              name="demonstrativo_faturamento_empresa"
            />
            <!-- {{ EmpresaConfiguracaoDemonstrativoEnum.NAO_RECEBE.nome }} -->
            Não enviar
          </label>
        </div>
        <div class="col col-2" @click="showMessageNotContatosAprova">
          <label
            :class="['cursop', { 'text-secondary': !contatosCount.APROVA }]"
            for="empresa_configuracao_DF_via_email"
          >
            <input
              :disabled="!contatosCount.APROVA || IS_DISABLED"
              id="empresa_configuracao_DF_via_email"
              :value="EmpresaConfiguracaoDemonstrativoEnum.VIA_EMAIL"
              type="radio"
              v-model="model.tipoEnvioDemonstrativo"
              name="demonstrativo_faturamento_empresa"
            />
            <!-- {{ EmpresaConfiguracaoDemonstrativoEnum.VIA_EMAIL.nome }} -->
            Enviar por e-mail
          </label>
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <form-row-header text="Envio automático de Propostas" />
    </div>
    <div v-if="model" class="mt-4" @click="showMessageNotContatosAprova">
      <div class="d-flex align-items-center">
        <div class="col col-2">
          <label
            style="width: auto !important"
            :class="[
              'cursop',
              { 'text-secondary': !sendDemonstrativoFaturamento },
            ]"
            for="empresa_configuracao_PROPOSTA_curto_prazo"
          >
            <input
              :disabled="!sendDemonstrativoFaturamento || IS_DISABLED"
              id="empresa_configuracao_PROPOSTA_curto_prazo"
              type="checkbox"
              v-model="model.curtoPrazo"
            />
            Curto Prazo
          </label>
        </div>
        <div class="col">
          <label
            style="width: auto !important"
            :class="[
              'cursop',
              { 'text-secondary': !sendDemonstrativoFaturamento },
            ]"
            for="empresa_configuracao_PROPOSTA_reducao_montantes"
          >
            <input
              :disabled="!sendDemonstrativoFaturamento || IS_DISABLED"
              id="empresa_configuracao_PROPOSTA_reducao_montantes"
              type="checkbox"
              v-model="model.reducaoDeMontantes"
            />
            Redução de Montantes
          </label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="my-4 d-flex align-items-center justify-content-end">
        <button
          class="btn btn-secondary"
          @click="$router.push({ name: 'BuscarEmpresas' })"
        >
          Cancelar
        </button>
        <button
          @click="update"
          class="btn btn-primary ms-3"
          v-if="
            $can('CENTRAL_CADASTROS_EDITAR') ||
            $can('CENTRAL_CADASTROS_APROVAR') ||
            $can('CENTRAL_CADASTROS_PARAMETRIZAR')
          "
        >
          Salvar Configurações
        </button>
      </div>
    </div>
    <hr />
    <div class="row mt-5 d-flex align-items-center">
      <switch-pos-venda-field
        :modelProp="model"
        ref="switchPosVendaField"
        :disabled="
          !this.$can('CENTRAL_CADASTROS_EDITAR') &&
          !this.$can('CENTRAL_CADASTROS_APROVAR') &&
          !this.$can('CENTRAL_CADASTROS_PARAMETRIZAR')
        "
      />
    </div>
    <div class="row mt-3">
      <search-bar
        :cols="5"
        ref="searchBar"
        @doSearch="doSearch"
        title="Buscar Agente Pós-Venda"
        placeholder="Busque por Nome ou E-mail"
        :disabled="(model && !model.hasAgentePosVendaAssociated) || IS_DISABLED"
      />
    </div>
    <div class="row">
      <form-row-header class="mt-5" text="Agentes Pós-Venda Encontrados" />
    </div>
    <table-agente-pos-venda
      :disabled="IS_DISABLED"
      :isAssociate="model && !!agentePosVendaAssociated"
      :agentePosVendaList="agentePosVendaList"
      :agentePosVendaSelected.sync="agentePosVendaSelected"
    />
    <div class="row">
      <div class="mt-3 d-flex align-items-center justify-content-end">
        <button
          v-if="!IS_DISABLED"
          class="btn btn-primary ms-3"
          @click="associateAgentePosVenda"
          :disabled="!agentePosVendaSelected"
        >
          Associar Pós-Venda
        </button>
      </div>
    </div>
    <div class="row">
      <form-row-header class="mt-5" text="Agente Pós-Venda vinculado" />
    </div>
    <table-agente-pos-venda-associado
      :disabled="IS_DISABLED"
      @dissociateAgente="showDissociateModal"
      :isAssociate="model && model.hasAgentePosVendaAssociated"
      :habilitadoDemonstrativo="empresa && empresa.habilitadoDemonstrativo"
      :agentePosVendaAssociated="agentePosVendaAssociated"
    />
    <div class="row">
      <form-row-header
        class="mt-3"
        text="Histórico de vínculo de Agente Pós-Venda"
      />
    </div>
    <table-historico-pos-venda :config="historicoRelacionamentoConfig" />
  </div>
</template>

<script>
import CRUDTemplate from "@/components/CRUDTemplate";
import SearchBar from "@/components/SearchBar.vue";
import FormRowHeader from "@/components/forms/FormRowHeader";
import SwitchPosVendaField from "@/components/forms/fields/empresa/SwitchPosVendaField.vue";
import TableAgentePosVenda from "@/components/tables/TableAgentePosVenda";
import TableAgentePosVendaAssociado from "@/components/tables/TableAgentePosVendaAssociado";
import TableHistoricoPosVenda from "@/components/tables/TableHistoricoPosVenda.vue";
import { CONFIRMATION, HTTP_RESPONSE, PENDENCIES } from "@/constants/strings";
import EmpresaConfiguracaoDemonstrativoEnum from "@/enums/EmpresaConfiguracaoDemonstrativoEnum";
import AgentePosVendaModel from "@/models/empresa/AgentePosVendaModel";
import EmpresaModel from "@/models/empresa/EmpresaModel";
import EmpresaPosVendasModel from "@/models/empresa/EmpresaPosVendasModel";
import EmpresaPosVendaHistoricoService from "@/services/portal/empresa/EmpresaPosVendaHistoricoService";
import EmpresaPosVendaService from "@/services/portal/empresa/EmpresaPosVendaService";
import EmpresaService from "@/services/portal/empresa/EmpresaService";
import { checkDFAprovaPendencie } from "@/utils/functionUtils";

export default {
  components: {
    SearchBar,
    FormRowHeader,
    SwitchPosVendaField,
    TableAgentePosVenda,
    TableAgentePosVendaAssociado,
    TableHistoricoPosVenda,
  },
  extends: CRUDTemplate,
  data() {
    return {
      contatosCount: {
        APROVA: 0,
        NOTIFICADO: 0,
        NA: 0,
      },
      showDemonstrativoHelp: false,
      agentePosVendaSelected: null,
      agentePosVendaList: new Array(),
      historicoRelacionamentoConfig: {
        conteudo: new Array(),
        pagination: undefined,
        filter: {
          page: 0,
          size: 10,
        },
      },
    };
  },
  computed: {
    IS_DISABLED() {
      return (
        !this.$can("CENTRAL_CADASTROS_EDITAR") &&
        !this.$can("CENTRAL_CADASTROS_APROVAR") &&
        !this.$can("CENTRAL_CADASTROS_PARAMETRIZAR")
      );
    },
    agentePosVendaAssociated() {
      if (this.empresa?.usuarioPosVenda?.id) {
        return this.empresa.usuarioPosVenda;
      }
      return null;
    },
    EmpresaConfiguracaoDemonstrativoEnum: () =>
      EmpresaConfiguracaoDemonstrativoEnum,
    sendDemonstrativoFaturamento() {
      return (
        this.model.tipoEnvioDemonstrativo.id !==
        EmpresaConfiguracaoDemonstrativoEnum.NAO_RECEBE.id
      );
    },
  },
  mounted() {
    this.mountEmpresaPosVenda();
  },
  methods: {
    mountEmpresaPosVenda() {
      const { empresaId } = this.$parent;
      return new Promise((resolve, reject) => {
        this.loading = EmpresaService.getById({ empresaId })
          .then(({ data }) => {
            this.service = EmpresaPosVendaService;

            this.requestEntity = "empresa_configuracoes";
            this.empresa = new EmpresaModel(data);

            this.model = new EmpresaPosVendasModel(
              this.empresa.configuracao ?? new Object()
            );

            this.model.hasAgentePosVendaAssociated =
              this.empresa.usuarioPosVenda && !!this.empresa.usuarioPosVenda.id;

            this.empresa.usuarioPosVenda = new AgentePosVendaModel(
              this.empresa.usuarioPosVenda ?? new Object()
            );

            this.getHistoricoByEmpresa();
            this.agentePosVendaSelected = null;

            this.checkContatosPermissoes();
            this.setHeaderText(this.empresa.razaoSocial);
            this.setBreadcrumbsParam(this.empresa.tipo.nome);
            resolve();
          })
          .catch((error) => {
            this.mxHandleRequestError(error, "empresa_configuracoes");
            reject();
          });
      });
    },
    getHistoricoByEmpresa() {
      const { id: empresaId } = this.empresa;
      const { page, size } = this.historicoRelacionamentoConfig.filter;
      this.loading = EmpresaPosVendaHistoricoService.getHistoricoByEmpresa({
        empresaId,
        page,
        size,
      })
        .then(({ data }) => {
          this.historicoRelacionamentoConfig.conteudo = data.content;
          this.historicoRelacionamentoConfig.pagination = data;
        })
        .catch((error) => {
          this.mxHandleRequestError(error, "empresa_configuracoes");
        });
    },
    associateAgentePosVenda() {
      if (this.agentePosVendaSelected) {
        const { id: empresaId } = this.empresa;
        const { id: usuarioPosVendaId, habilitadoDemonstrativo } =
          this.agentePosVendaSelected;

        this.loading = this.service
          .associateAgente({
            empresaId,
            usuarioPosVendaId,
            habilitadoDemonstrativo,
          })
          .then(() => {
            this.mxToggleToast("Agente Pós-Venda vinculado com sucesso!");
            this.mountEmpresaPosVenda();
            this.agentePosVendaSelected = null;
          })
          .catch((error) => this.mxHandleRequestError(error));
      } else {
        this.mxToggleErrorMessageAlert(
          "Houve um erro ao associar agente Pós-Venda à empresa"
        );
      }
    },
    dissociateAgente(wait = false) {
      if (this.empresa?.usuarioPosVenda?.id) {
        const { id: empresaId } = this.empresa;

        this.loading = this.service
          .dissociateAgente({ empresaId })
          .then(async () => {
            this.mxToggleToast("Agente Pós-Venda desvinculado com sucesso!");
            await this.mountEmpresaPosVenda();
            if (wait) {
              this.model.hasAgentePosVendaAssociated = true;
            }
          })
          .catch((error) => this.mxHandleRequestError(error));
      }
    },
    showDissociateModal() {
      this.mxToggleWarningModal(
        CONFIRMATION.POS_VENDAS.UNBIND,
        undefined,
        undefined,
        undefined,
        undefined,
        true
      )
        .then(() => {
          this.dissociateAgente(true);
        })
        .catch(() => {});
    },
    resetPosVendasView(message, sendRequest = false) {
      this.mxToggleWarningModal(
        message,
        undefined,
        undefined,
        undefined,
        undefined,
        true
      )
        .then(() => {
          if (sendRequest) {
            this.dissociateAgente();
            this.agentePosVendaSelected = null;
            this.isAssociate = false;
            this.agentePosVendaList = new Array();
            this.empresa.usuarioPosVenda = new Object();
            this.model.hasAgentePosVendaAssociated = false;
          } else {
            this.doSearch("", true);
            this.model.hasAgentePosVendaAssociated = false;
          }
        })
        .catch(() => (this.model.hasAgentePosVendaAssociated = true));
    },
    showMessageNotContatosAprova() {
      if (this.IS_DISABLED) return;
      if (this.contatosCount.APROVA) return false;
      this.mxToggleWarningModal(
        PENDENCIES.NOT_CONTATOS_APROVA,
        "Fechar",
        null,
        "ATENÇÃO",
        false
      );
    },
    setPage(page) {
      this.filter.page = page;
      this.doSearch();
    },
    doSearch(search = this.$refs.searchBar.content) {
      this.loading = this.service
        .listAgentes({
          ativo: true,
          filtro: search,
        })
        .then(({ data }) => {
          if (data) {
            this.agentePosVendaList = data.map(
              (agente) => new AgentePosVendaModel(agente)
            );
          } else {
            this.mxToggleWarningMessageAlert(
              "Nenhum agente pós-venda encontrado!",
              true
            );
            this.agentePosVendaList = new Array();
          }
        })
        .catch((error) => this.mxHandleRequestError(error));
    },
    update() {
      const { empresaId } = this.$parent;
      this.loading = EmpresaPosVendaService.updateConfiguracoes(
        this.model.getData(),
        empresaId
      )
        .then(() => {
          this.mxToggleToast(
            HTTP_RESPONSE[this.requestEntity.toUpperCase()].SUCCESS.UPDATE
          );
          EmpresaService.getById({ empresaId })
            .then(async ({ data: empresa }) => {
              if (checkDFAprovaPendencie(empresa)) {
                this.mxToggleWarningMessageAlert(
                  PENDENCIES.SEND_DEMONSTRATIVOS_FATURAMENTO_OFF,
                  false,
                  true
                );
              } else {
                this.mxResetWarningPendencies(true);
              }
            })
            .catch((error) =>
              this.mxHandleRequestError(error, "empresa_configuracoes")
            );
        })
        .catch((error) =>
          this.mxHandleRequestError(error, "empresa_configuracoes")
        );
    },
    checkContatosPermissoes() {
      this.empresa.contatos.forEach(({ permissoes }) => {
        const id = permissoes.demonstrativo?.id;
        if (!id) return;
        this.contatosCount[id.substring(11)]++;
      });
      this.empresa.contatosGestor.forEach(({ permissoes }) => {
        const id = permissoes.demonstrativo?.id;
        if (!id) return;
        this.contatosCount[id.substring(11)]++;
      });
    },
  },
  watch: {
    "model.tipoEnvioDemonstrativo": {
      immediate: true,
      handler() {
        if (!this.model) return;
        if (
          this.model.tipoEnvioDemonstrativo.id ===
          EmpresaConfiguracaoDemonstrativoEnum.NAO_RECEBE.id
        ) {
          if (this.contatosCount.APROVA) {
            this.showDemonstrativoHelp = true;
          }
          this.model.curtoPrazo = false;
          this.model.reducaoDeMontantes = false;
        } else {
          this.showDemonstrativoHelp = false;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/style.scss";
</style>
