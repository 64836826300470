var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"async",rawName:"v-async",value:(_vm.loading),expression:"loading"}],staticClass:"mt-5"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('form-row-header',{attrs:{"text":"Envio automático de Demonstrativo de Faturamento"}}),(_vm.showDemonstrativoHelp)?_c('help',{staticClass:"ms-2",attrs:{"boxAlign":"right"}},[_vm._v(" Para enviar demonstrativos automáticos aos contatos parametrizados, configure aqui o envio automático para \"Enviar por e-mail\" ")]):_vm._e()],1)]),(_vm.model)?_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"col col-2"},[_c('label',{staticClass:"cursop",attrs:{"for":"empresa_configuracao_DF_nao_recebe"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.tipoEnvioDemonstrativo),expression:"model.tipoEnvioDemonstrativo"}],attrs:{"disabled":!_vm.$can('CENTRAL_CADASTROS_EDITAR') &&
              !_vm.$can('CENTRAL_CADASTROS_APROVAR') &&
              !_vm.$can('CENTRAL_CADASTROS_PARAMETRIZAR'),"id":"empresa_configuracao_DF_nao_recebe","type":"radio","name":"demonstrativo_faturamento_empresa"},domProps:{"value":_vm.EmpresaConfiguracaoDemonstrativoEnum.NAO_RECEBE,"checked":_vm._q(_vm.model.tipoEnvioDemonstrativo,_vm.EmpresaConfiguracaoDemonstrativoEnum.NAO_RECEBE)},on:{"change":function($event){return _vm.$set(_vm.model, "tipoEnvioDemonstrativo", _vm.EmpresaConfiguracaoDemonstrativoEnum.NAO_RECEBE)}}}),_vm._v(" Não enviar ")])]),_c('div',{staticClass:"col col-2",on:{"click":_vm.showMessageNotContatosAprova}},[_c('label',{class:['cursop', { 'text-secondary': !_vm.contatosCount.APROVA }],attrs:{"for":"empresa_configuracao_DF_via_email"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.tipoEnvioDemonstrativo),expression:"model.tipoEnvioDemonstrativo"}],attrs:{"disabled":!_vm.contatosCount.APROVA || _vm.IS_DISABLED,"id":"empresa_configuracao_DF_via_email","type":"radio","name":"demonstrativo_faturamento_empresa"},domProps:{"value":_vm.EmpresaConfiguracaoDemonstrativoEnum.VIA_EMAIL,"checked":_vm._q(_vm.model.tipoEnvioDemonstrativo,_vm.EmpresaConfiguracaoDemonstrativoEnum.VIA_EMAIL)},on:{"change":function($event){return _vm.$set(_vm.model, "tipoEnvioDemonstrativo", _vm.EmpresaConfiguracaoDemonstrativoEnum.VIA_EMAIL)}}}),_vm._v(" Enviar por e-mail ")])])])]):_vm._e(),_c('div',{staticClass:"row mt-5"},[_c('form-row-header',{attrs:{"text":"Envio automático de Propostas"}})],1),(_vm.model)?_c('div',{staticClass:"mt-4",on:{"click":_vm.showMessageNotContatosAprova}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"col col-2"},[_c('label',{class:[
            'cursop',
            { 'text-secondary': !_vm.sendDemonstrativoFaturamento } ],staticStyle:{"width":"auto !important"},attrs:{"for":"empresa_configuracao_PROPOSTA_curto_prazo"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.curtoPrazo),expression:"model.curtoPrazo"}],attrs:{"disabled":!_vm.sendDemonstrativoFaturamento || _vm.IS_DISABLED,"id":"empresa_configuracao_PROPOSTA_curto_prazo","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.model.curtoPrazo)?_vm._i(_vm.model.curtoPrazo,null)>-1:(_vm.model.curtoPrazo)},on:{"change":function($event){var $$a=_vm.model.curtoPrazo,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.model, "curtoPrazo", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.model, "curtoPrazo", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.model, "curtoPrazo", $$c)}}}}),_vm._v(" Curto Prazo ")])]),_c('div',{staticClass:"col"},[_c('label',{class:[
            'cursop',
            { 'text-secondary': !_vm.sendDemonstrativoFaturamento } ],staticStyle:{"width":"auto !important"},attrs:{"for":"empresa_configuracao_PROPOSTA_reducao_montantes"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.reducaoDeMontantes),expression:"model.reducaoDeMontantes"}],attrs:{"disabled":!_vm.sendDemonstrativoFaturamento || _vm.IS_DISABLED,"id":"empresa_configuracao_PROPOSTA_reducao_montantes","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.model.reducaoDeMontantes)?_vm._i(_vm.model.reducaoDeMontantes,null)>-1:(_vm.model.reducaoDeMontantes)},on:{"change":function($event){var $$a=_vm.model.reducaoDeMontantes,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.model, "reducaoDeMontantes", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.model, "reducaoDeMontantes", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.model, "reducaoDeMontantes", $$c)}}}}),_vm._v(" Redução de Montantes ")])])])]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"my-4 d-flex align-items-center justify-content-end"},[_c('button',{staticClass:"btn btn-secondary",on:{"click":function($event){return _vm.$router.push({ name: 'BuscarEmpresas' })}}},[_vm._v(" Cancelar ")]),(
          _vm.$can('CENTRAL_CADASTROS_EDITAR') ||
          _vm.$can('CENTRAL_CADASTROS_APROVAR') ||
          _vm.$can('CENTRAL_CADASTROS_PARAMETRIZAR')
        )?_c('button',{staticClass:"btn btn-primary ms-3",on:{"click":_vm.update}},[_vm._v(" Salvar Configurações ")]):_vm._e()])]),_c('hr'),_c('div',{staticClass:"row mt-5 d-flex align-items-center"},[_c('switch-pos-venda-field',{ref:"switchPosVendaField",attrs:{"modelProp":_vm.model,"disabled":!this.$can('CENTRAL_CADASTROS_EDITAR') &&
        !this.$can('CENTRAL_CADASTROS_APROVAR') &&
        !this.$can('CENTRAL_CADASTROS_PARAMETRIZAR')}})],1),_c('div',{staticClass:"row mt-3"},[_c('search-bar',{ref:"searchBar",attrs:{"cols":5,"title":"Buscar Agente Pós-Venda","placeholder":"Busque por Nome ou E-mail","disabled":(_vm.model && !_vm.model.hasAgentePosVendaAssociated) || _vm.IS_DISABLED},on:{"doSearch":_vm.doSearch}})],1),_c('div',{staticClass:"row"},[_c('form-row-header',{staticClass:"mt-5",attrs:{"text":"Agentes Pós-Venda Encontrados"}})],1),_c('table-agente-pos-venda',{attrs:{"disabled":_vm.IS_DISABLED,"isAssociate":_vm.model && !!_vm.agentePosVendaAssociated,"agentePosVendaList":_vm.agentePosVendaList,"agentePosVendaSelected":_vm.agentePosVendaSelected},on:{"update:agentePosVendaSelected":function($event){_vm.agentePosVendaSelected=$event},"update:agente-pos-venda-selected":function($event){_vm.agentePosVendaSelected=$event}}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"mt-3 d-flex align-items-center justify-content-end"},[(!_vm.IS_DISABLED)?_c('button',{staticClass:"btn btn-primary ms-3",attrs:{"disabled":!_vm.agentePosVendaSelected},on:{"click":_vm.associateAgentePosVenda}},[_vm._v(" Associar Pós-Venda ")]):_vm._e()])]),_c('div',{staticClass:"row"},[_c('form-row-header',{staticClass:"mt-5",attrs:{"text":"Agente Pós-Venda vinculado"}})],1),_c('table-agente-pos-venda-associado',{attrs:{"disabled":_vm.IS_DISABLED,"isAssociate":_vm.model && _vm.model.hasAgentePosVendaAssociated,"habilitadoDemonstrativo":_vm.empresa && _vm.empresa.habilitadoDemonstrativo,"agentePosVendaAssociated":_vm.agentePosVendaAssociated},on:{"dissociateAgente":_vm.showDissociateModal}}),_c('div',{staticClass:"row"},[_c('form-row-header',{staticClass:"mt-3",attrs:{"text":"Histórico de vínculo de Agente Pós-Venda"}})],1),_c('table-historico-pos-venda',{attrs:{"config":_vm.historicoRelacionamentoConfig}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }